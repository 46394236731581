<template>
  <div class="content-wrapper wrapper">
    <div class="wrapper">
      <div class="d-flex justify-space-between">
        <h1 class="pageHeader">
          {{ $t('label.history') }}
        </h1>
      </div>
      <!-- Payment History Content Start: -->
      <div class="mt-15">
        <div class="pb-5">{{ $t('label.subscription') }}</div>
        <hr class="divider" />
        <v-row
          v-for="(item, index) in paymentHistoryObj"
          :key="index"
          no-gutters
          class="listBg justify-space-between pa-5 mt-6"
        >
          <v-col cols="9">{{ item.month }}</v-col>
          <v-col cols="2" class="ml-auto text-right">
            <v-icon color="white cursor-pointer" @click="getPdf(item.value)"
              >mdi-tray-arrow-down</v-icon
            >
          </v-col>
        </v-row>
      </div>
      <!-- Payment History Content End: -->
    </div>
  </div>
</template>

<script>
  import {
    PAYMENT_GET_PAYMENT_HISTORY,
    PAYMENT_INITIAL_GET_PAYMENT_HISTORY_STATE,
  } from '@/store/payment.module';
  import {
    PAYMENT
  } from '@/api';

  export default {
    name: 'Payment',
    data: () => ({
      paymentHistoryObj: []
    }),
    created() {
      this.initialGetPaymentHistory();
    },
    computed: {
      paymentHistoryCompleted() {
        return this.$store.state.payment.paymentHistory.complete;
      },
    },
    watch: {
      paymentHistoryCompleted() {
        let response = this.$store.state.payment.paymentHistory;
        if (response.complete) {
          this.importPaymentHistoryObj(response);
        }
      },
    },
    methods: {
      importPaymentHistoryObj(response) {
        this.paymentHistoryObj = response.data;
        if (this.paymentHistoryObj) {
          this.$store.dispatch(PAYMENT_INITIAL_GET_PAYMENT_HISTORY_STATE);
        }
      },
      initialGetPaymentHistory() {
        this.$store.dispatch(PAYMENT_GET_PAYMENT_HISTORY);
      },
      async getPdf(value) {
        const result = await PAYMENT.printPaymentHistory({date: value});
        const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
        window.open(url)
      }
    }
  };
</script>

<style></style>
